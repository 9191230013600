import React, { PureComponent } from "react";
import styled from "styled-components";
import Image from "next/image";
import CustomFont from "../../components/CustomFont";
import { Color as Colors, Color } from '../../src/utils/Color'
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import * as propertySelectors from "../../src/selectors/property";
import * as projectSelectors from "../../src/selectors/project";
import _ from "lodash";
import Helpers from "../../src/utils/Helpers";
import { getPropertyDetailTypeCode, getPropertyPriceTypeCode } from '../../src/selectors/property'
import { maxDevice } from "../../src/utils/breakpoints";
const ProjectContainer = styled.a`
  width: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const ProjectDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ProjectDetailStaticContainer = styled.div`
  padding: 3px 15px;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 10px;
`;

const ProjectDetailItemContainer = styled.div`
  padding: 3px 15px;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 10px;
  left: 0px;
`;

const ImageContainer = styled.div`
  height: 220px;
  width: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  @media ${maxDevice.md} {
    height: 190px;
    }
  }
`;

const TitleContainer = styled.div`
  display: inline-block;
  box-sizing: border-box;
  white-space: pre-line;
  word-wrap: break-word;
  margin-top: 5px;
  max-width: 100%;
`;

const BottomItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const BottomItemStyle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const TitleTextContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
  //min-height: 48px;
  color: ${Color.blackColor};
`;
const DescTextContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 0.8rem;
  color: ${Color.blackColor};

  // @media ${maxDevice.sm} {
  //   padding-left: 5px;
  // }
`;
const BottomPriceTextContainer = styled.div`
 display: flex;
  flex-direction: column;
  @media ${maxDevice.sm} {
    display: flex;
    //align-items: center;
    flex-direction: column;
  }
`;

const laberColor = [
  {
    title: "Residential",
    lightColor: "#FFD8D2",
    color: "#D93F2A"
  },
  {
    title: "Industrial",
    lightColor: "#D5CAF3",
    color: "#7951EA"
  },
  {
    title: "Commercial",
    lightColor: "#C8D4F4",
    color: "#3462F4"
  }
];

const PropertyItemContainer = styled.div`
  padding: 5px 10px;
  border-radius: 20px;
  width: fit-content;
  border: 1px solid #3462F4;
`;
const PropertyStatusTitle = styled.div`
  font-size: 0.7rem;
  color: #3462F4
`;

class HomePropertyItem extends PureComponent {
  onClickItem = e => {
    e.preventDefault();
    const { onClickItem, item } = this.props;

    const slug = propertySelectors.getPropertyDetailSlug(item);

    onClickItem && onClickItem(slug);
  };

  render() {
    const { item } = this.props;
    const image = propertySelectors.getPropertyDetailImageMediumSrc(item);
    const kind = propertySelectors.getPropertyDetailKindName(item);
    const name = propertySelectors.getPropertyDetailName(item);
    const category = propertySelectors.getPropertyDetailCategoryName(item);
    const bed = propertySelectors.getPropertyDetailRoomBedText(item);
    const bath = propertySelectors.getPropertyDetailRoomBathText(item);
    const floorSize = propertySelectors.getPropertyDetailSizeFloorText(item);
    const sale = propertySelectors.getPropertyDetailSalePriceText(item);
    const type = propertySelectors.getPropertyDetailTypeName(item);
    const cityName = propertySelectors.getPropertyDetailCityName(item);
    const stateName = propertySelectors.getPropertyDetailStateName(item);
    const postcode = propertySelectors.getPropertyDetailPostCode(item);
    const countryName = propertySelectors.getPropertyDetailCountryName(item);
    const priceObject = propertySelectors.getPropertyDetailSaleOrRentObject(item);
    const slug = propertySelectors.getPropertyDetailSlug(item);
    const hostname = Helpers.isProduction
      ? typeof window !== "undefined" && window.location.hostname
        ? window.location.hostname
        : ""
      : "localhost:3000";
    const http = Helpers.isProduction ? "https" : "http";
    const formattedPrice = _.get(priceObject, ["price", "text"], "-");
    const numericalValue = parseFloat(formattedPrice.replace(/[^\d.-]/g, "")); // Parse the numerical value
    const integerPart = Math.floor(numericalValue); // Convert to integer (removing decimal)
    const formattedIntegerPart = integerPart.toLocaleString();
    const formattedAmount = isNaN(integerPart)
      ? "-"
      : `${formattedPrice.split(" ")[0]} ${formattedIntegerPart}`;

    const propertyLabelColor = _.find(Helpers.laberColor(), item => {
      const propertyTitle = _.get(item, ["title"], "");
      return _.isEqual(type, propertyTitle);
    });
    const propertyCurrentLabelColor = _.get(propertyLabelColor, ["color"], "");
    const forSales = propertySelectors.getPropertyDetailAttributeForSales(item);
    const forRent = propertySelectors.getPropertyDetailAttributeForRent(item);
    const propertyStatus = forSales ? 'For Sale' : forRent ? 'For Rent' : "";
    const landSize = propertySelectors.getPropertyDetailSizeLandText(item);
    const categoryList = propertySelectors.getPropertyDetailCategoryName(item);

    const landCategories = ["Agricultural Land", "Residential Land", "Commercial Land", "Industrial Land"];

    return (
      <ProjectContainer
        href={`${process.env.WEBSITE_URL}property/${slug}`}
        onClick={this.onClickItem}
      >
        <div>
          <ImageContainer>
            <Image
              src={!_.isEmpty(image) ? image : "placeholderBanner.png"}
              loader={() => image}
              layout="fill"
              style={{ borderRadius: 4 }}
              objectFit="cover"
              alt={name}
              placeholder="blur"
              blurDataURL={image}

            />
            <ProjectDetailItemContainer>
              <CustomFont size="small" styles={{ color: "white", fontWeight: "600" }}>
                {category}
              </CustomFont>
            </ProjectDetailItemContainer>
          </ImageContainer>
          <ProjectDetailContainer>
            <ProjectDetailStaticContainer
              style={{ backgroundColor: "white", border: `1px solid ${propertyCurrentLabelColor}` }}
            >
              <CustomFont size="small" styles={{ color: propertyCurrentLabelColor, fontWeight: "600" }}>
                {type}
              </CustomFont>
              </ProjectDetailStaticContainer>
          </ProjectDetailContainer>
          <TitleContainer>
            <TitleTextContainer><CustomFont size="normal" styles={{ fontWeight:"600" }}>{name}</CustomFont></TitleTextContainer>
          </TitleContainer>
          <PropertyItemContainer>
            <PropertyStatusTitle style={{ color: "#3462F4" }}>
              {`${propertyStatus}`}
            </PropertyStatusTitle>
          </PropertyItemContainer>
        </div>
        <div>
          <BottomItemContainer>
            <div style={{ display: "flex", marginRight: "10px" }}>
              <BottomItemStyle style={{ marginRight: "10px" }}>
                <CustomFont
                  size="small"
                  styles={{
                    color: Color.EnhanceIconColor,
                    marginRight: "3px"
                  }}
                >
                  {bed}
                </CustomFont>
                <BedIcon fontSize="20px" style={{ fontSize: "20px", color: Colors.EnhanceIconColor }} />
              </BottomItemStyle>
              <BottomItemStyle style={{}}>
                <CustomFont
                  size="small"
                  styles={{
                    color: Color.EnhanceIconColor,
                    marginRight: "3px"
                  }}
                >
                  {bath}
                </CustomFont>
                <ShowerIcon fontSize="20px" style={{ fontSize: "20px", color: Colors.EnhanceIconColor }} />
              </BottomItemStyle>
            </div>
            <BottomItemStyle style={{ flex: 1 }}>
              <CustomFont
                size="small"
                styles={{
                  color: Color.EnhanceIconColor,
                  marginRight: "3px"
                }}
              >
                {`${_.includes(landCategories, categoryList) ? landSize : floorSize} `}
              </CustomFont>
              <SquareFootIcon fontSize="20px" style={{ fontSize: "20px", color: Colors.EnhanceIconColor }} />
            </BottomItemStyle>
          </BottomItemContainer>
          <BottomPriceTextContainer>
          <CustomFont
            // size="large"
            variant="caption"
            styles={{
              color: Color.primaryColor,
              fontWeight: "600",
              // marginTop: "10px"
            }}
          >
            {/*{formattedPrice}*/}
            {/*{Math.trunc(parseFloat(formattedPrice.replace(/,/g, "")))}*/}
            {formattedAmount}
          </CustomFont>
          <TitleContainer>
            <DescTextContainer>
              <CustomFont size="xsmall">
              {`${cityName} ${postcode} ${stateName} ${countryName}`}
              </CustomFont>
              </DescTextContainer>
          </TitleContainer>
          </BottomPriceTextContainer>
        </div>
      </ProjectContainer>
    );
  }
}

export default HomePropertyItem;
